import React from 'react'
import { Link } from 'gatsby'
import { Col, Container, Row } from 'react-bootstrap'
import IndustriesBox from './industriesBox';
import allArrayData from '../../allData.js';



const IndustriesCom = (props) => {

  const { dataoen, datatwo } = props;

  return (
    <>
      <section className={`creating portfolio ${props.bg ? 'ronak-background' : ''}`}>
        <Container>
          <div className='heading text-center' data-aos="zoom-in"  >
            <h2>{props.h1}</h2>
          </div>
          <div className='mt-5'>
            <Row className='justify-content-center'>
              {
                props.industriesData.map((data, i) =>
                  <div key={i} className="mt-3 resize-img w-auto" >
                    <IndustriesBox title={data.title} img={data.img} upperCase={props.upperCase} />
                  </div>
                )
              }
            </Row>
          </div>
          {
            props.buttonOn ?
              <div className='d-flex justify-content-center mt-5' >
                <Link to='/industries' className='view-all' data-aos="zoom-in" >Learn More</Link>
              </div>
              : ''
          }

        </Container>
      </section>
    </>
  )
}

export default IndustriesCom