import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'gatsby';

function RonakHero({ userData }) {

    const windowWidth = typeof window !== "undefined" && window.innerWidth;

    return (
        <section>
            <div className='hero-ronak'>
                <Container>
                    <Row className='align-items-center justify-content-center flex-xl-row flex-column'>
                        <Col xl={windowWidth < 1600 && windowWidth > 1200 ? 4 : 3} className="hero-border-right text-xxl-start text-center">
                            <div className="hero-img-info text-xl-center text-lg-start text-center">
                                <img src={`${userData.image}`} alt={`${userData.tag}`} className='hero-img-ronak mx-auto' />
                                {/* <img src={`${userData.image}`} alt={`${userData.tag}`} className='hero-img-ronak mx-auto rounded-circle bg-white' /> */}
                                <div className="d-flex flex-column">
                                    <h2 className='text-uppercase d-inline-block text-skyblue'>{userData.name}</h2>
                                    <h3 className='text-uppercase d-inline-block'>{userData.designation}</h3>
                                    <div className="ronak-hero-sub-info">
                                        <div className="social d-flex justify-content-xl-center justify-content-lg-start justify-content-center">
                                            {userData.linkedin && <Link href={userData.linkedin} target="_blank"><img src="../assets/img/ronak-pipaliya/linkedin.png" alt="linkedin" /></Link>}
                                            {userData.facebook && <Link href={userData.facebook} target="_blank"><img src="../assets/img/ronak-pipaliya/facebook.png" alt="facebook" /></Link>}
                                            {userData.skype && <Link href={userData.skype} target="_blank"><img src="../assets/img/ronak-pipaliya/skype.png" alt="skype" /></Link>}
                                            {userData.behance && <Link href={userData.behance} target="_blank"><img src="../assets/img/ronak-pipaliya/behance.png" alt="behance" /></Link>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xl={windowWidth < 1600 && windowWidth > 1200 ? 8 : 9} className="hero-border-left">
                            <div className='hero-info'>
                                <div className='hero-info-top'>
                                    <Row>
                                        {/* <Col sm={8}> */}
                                        <Col>
                                            <div className="ronak-hero-sub-heading">
                                                <h4 className='text-uppercase'>Contact Info</h4>
                                            </div>
                                            <div className="ronak-hero-sub-info">
                                                {/* <Row className='flex-xl-column flex-md-row flex-column'> */}
                                                <Row className=''>
                                                    <Col xl={5} lg={5} md={5}>
                                                        <div className="ronak-hero-phone d-flex">
                                                            <div className='phone-ronak me-2'><p className='text-skyblue'>Phone:</p></div>
                                                            <div className='ronak-numbers'>
                                                                {userData.phones && userData.phones.map((_phone, i) => (
                                                                    <p key={i}><Link href={`tel:${_phone}`}>{_phone}</Link></p>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col xl={7} lg={7} md={7}>
                                                        <div className="ronak-hero-email d-flex">
                                                            <div className='email-ronak me-2'><p className='text-skyblue'>E-mail:</p></div>
                                                            <div className='ronak-emails'>
                                                                {userData.emails && userData.emails.map((_email, i) => (
                                                                    <p key={i}><Link href={`mailto:${_email}`}>{_email}</Link></p>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                        {/* <Col sm={4} className="mt-sm-0 mt-4"> */}
                                        {/* <div className="ronak-hero-sub-heading">
                                                <h4 className='text-uppercase'>Social</h4>
                                            </div> */}
                                        {/* <div className="ronak-hero-sub-info">
                                                <div className="social d-flex">
                                                    {linkedin && <Link href={linkedin} target="_blank"><img src="../assets/img/ronak-pipaliya/linkedin.png" alt="linkedin" /></Link>}
                                                    {facebook && <Link href={facebook} target="_blank"><img src="../assets/img/ronak-pipaliya/facebook.png" alt="facebook" /></Link>}
                                                    {skype && <Link href={skype} target="_blank"><img src="../assets/img/ronak-pipaliya/skype.png" alt="skype" /></Link>}
                                                    {behance && <Link href={behance} target="_blank"><img src="../assets/img/ronak-pipaliya/behance.png" alt="behance" /></Link>}
                                                </div>
                                            </div> */}
                                        {/* </Col> */}
                                    </Row>
                                </div>
                                <div className='hero-paragraph'>
                                    {/* <p>But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful.</p> */}
                                    <ul className='m-0'>
                                        {
                                            userData.description && userData.description.map((_desc, i) => (
                                                <li key={i}>{_desc}</li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <a href="/contact-us" className='ronak-contact-btn position-fixed rounded-circle text-center bg-white d-inline-block'>
                <div className="d-flex align-items-center justify-content-center position-absolute w-100 h-100">
                    <img src="../assets/img/ronak-pipaliya/contact.png" alt="contact" />
                </div>
            </a>
        </section>
    )
}

export default RonakHero